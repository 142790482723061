.mint-default {
    @apply flex h-16 w-64 bg-mint;
}

.mint-hover {
    @apply flex h-16 w-32 bg-mint-hover;
}

.mint-press {
    @apply flex h-16 w-32 bg-mint-press;
}

.mint-focus {
    @apply flex h-16 w-32 bg-mint border-2 border-black;
}

.plum-default {
    @apply flex h-16 w-64 bg-plum;
}

.plum-hover {
    @apply flex h-16 w-32 bg-plum-hover;
}

.plum-press {
    @apply flex h-16 w-32 bg-plum-press;
}

.plum-focus {
    @apply flex h-16 w-32 bg-plum border-2 border-black;
}

.orca-default {
    @apply flex h-16 w-64 bg-orca;
}

.orca-hover {
    @apply flex h-16 w-32 bg-orca-hover;
}

.orca-press {
    @apply flex h-16 w-32 bg-orca-press;
}

.orca-focus {
    @apply flex h-16 w-32 bg-orca border-2 border-black;
}

.fire-default {
    @apply flex h-16 w-64 bg-fire;
}

.fire-hover {
    @apply flex h-16 w-32 bg-fire-hover;
}

.fire-press {
    @apply flex h-16 w-32 bg-fire-press;
}

.fire-focus {
    @apply flex h-16 w-32 bg-fire border-2 border-black;
}

.black {
    @apply flex h-16 w-24 bg-black;
}

.gray10 {
    @apply flex h-16 w-24 bg-gray10;
}

.gray20 {
    @apply flex h-16 w-24 bg-gray20;
}

.gray30 {
    @apply flex h-16 w-24 bg-gray30;
}

.gray40 {
    @apply flex h-16 w-24 bg-gray40;
}

.gray50 {
    @apply flex h-16 w-24 bg-gray50;
}

.gray60 {
    @apply flex h-16 w-24 bg-gray60;
}

.gray70 {
    @apply flex h-16 w-24 bg-gray70;
}

.gray80 {
    @apply flex h-16 w-24 bg-gray80;
}

.gray90 {
    @apply flex h-16 w-24 bg-gray90;
}

.white {
    @apply flex h-16 w-24 bg-white;
}
